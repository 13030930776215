{
	"nav": {
		"about": "Chi Sono",
		"projects": "Progetti",
		"contact": "Contattami"
	},
	"banner": {
		"greeting": "Ben trovati!",
		"intro": "Sono",
		"profession": ["Specialista in", "Sviluppo Web Full-Stack"]
	},
	"about": {
		"title": "CHI SONO",
		"text": [
			"Sono uno sviluppatore web con sede a Slough, Regno Unito, specializzato in soluzioni web innovative per aziende di livello enterprise.",
			"Con competenze avanzate in HTML5, CSS/SCSS, JavaScript, TypeScript, Node.js, Express, MongoDB e framework come React e Angular, mi dedico a creare esperienze utente eccezionali e ad integrarle perfettamente con gli obiettivi aziendali più complessi.",
			"La mia passione per la tecnologia, unita a un forte senso del problem solving e dell'innovazione, mi rende un asset strategico per team che cercano di superare le sfide del web moderno in contesti aziendali."
		]
	},
	"projects": {
		"header": "PROGETTI PERSONALI"
	},
	"contact": {
		"title": "CONTATTAMI",
		"form": {
			"name": "Nome",
			"message": "Messaggio",
			"send": "Invia"
		}
	}
}
