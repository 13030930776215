{
	"nav": {
		"about": "About",
		"projects": "Projects",
		"contact": "Contact"
	},
	"banner": {
		"greeting": "Welcome!",
		"intro": "I`m",
		"profession": ["Specialist in", "Full-Stack Web Development"]
	},
	"about": {
		"title": "ABOUT ME",
		"text": [
			"I am a web developer based in Slough, United Kingdom, specializing in innovative web solutions for enterprise-level businesses.",
			"With advanced skills in HTML5, CSS/SCSS, JavaScript, TypeScript, Node.js, Express, MongoDB, and frameworks like React and Angular, I craft exceptional user experiences that seamlessly align with complex business objectives.",
			"My passion for technology, coupled with a strong sense of problem-solving and innovation, makes me a strategic asset for teams looking to overcome modern web challenges in corporate settings."
		]
	},
	"projects": {
		"header": "SIDE PROJECTS"
	},
	"contact": {
		"title": "CONTACT ME",
		"form": {
			"name": "Your Name",
			"message": "Message",
			"send": "Send"
		}
	}
}
